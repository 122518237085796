
import {Component} from 'vue-property-decorator';
import TreeMapFilterable from '@/components/reports-v2/components/filterables/TreeMapFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import {CustomerSupplierDbCard, DimensionData, PermissionsGroup} from '@/store/models.def';
import * as echarts from 'echarts';

@Component({
	components: {
		TreeMapFilterable,
		SelectFilter,
	},
})
export default class CustomerAnalysisTreeMap extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange'];

	public result: any = [];
	public series: echarts.ECharts[] | any = [];

	public selectedGrouping: any = this.groupingList[1];
	public bigCustomerInput: number = 15000;
	public smallCustomerInput: number = 8000;
	public isCalculateGrouping: number = 0;

	public generateGrouping() {
		this.isCalculateGrouping += +1;
	}

	public get groupingList(): any [] {
    return [
      // { text: 'None', value: 'none' },
      {text: 'Customer', value: 'customers'},
      {text: 'Current Customer', value: 'currentCustomer'},
      {text: 'New Customer', value: 'newCustomer'},
      {text: 'Current vs New Customer', value: 'currentNew'},
      {text: 'Customer Size', value: 'customerSize'},
      {text: 'Company Category', value: 'customerCategory'},
      {text: 'Sales Agent', value: 'salesAgent'},
      {text: 'Area', value: 'area'},
      {text: 'Stock Item', value: 'stockItems'},
      {text: 'Stock Group', value: 'stockGroup'},

    ];
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedCustomers,
			selectedGrouping,
			isCalculateGrouping,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedCustomers,
			selectedGrouping,
			isCalculateGrouping,
		]);
	}

	public async expensiveCalc() {
		const salesRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateRange(this.selectedDateRange);

		const cnRef = FilteredDatabase.ref('creditNotes')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateRange(this.selectedDateRange);

		let tradeIvdnRef = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateRange(this.selectedDateRange)
			.includes('docType', ['IV', 'DN']);

		const salesAmount = await (await tradeIvdnRef.get()).getSum('amount');
		const salesCount = await (await tradeIvdnRef.get()).getCount();
		const salesAmountMinMax = await (
			await tradeIvdnRef.get()
		).getMinMax('amount');

		if (this.isCalculateGrouping === 0) {
			this.smallCustomerInput = Math.round((salesAmount / salesCount) / 1000) * 1000;
			this.bigCustomerInput = Math.round((salesAmountMinMax.max / 2 ) / 1000) * 1000;
		}

		const customerList = [...(await (await tradeIvdnRef.get()).getSet('code'))];

		const agentList = [...(await (await tradeIvdnRef.get()).getSet('agent'))];

		const newRef = FilteredDatabase.ref('globalCustomers')
			.customers(customerList)
			.agents(this.selectedAgents)
			.numberRange('firstDeal', [
				['>=', this.selectedDateRange[0]],
				['<=', this.selectedDateRange[1]],
			]);

		const newCustomerList = [...(await (await newRef.get()).getSet('code'))];

		const existingRef = FilteredDatabase.ref('globalCustomers')
			.customers(customerList)
			.agents(this.selectedAgents)
			.numberRange('firstDeal', [
				['>', Number.NEGATIVE_INFINITY],
				['<', this.selectedDateRange[0]],
			]);

		const existingCustomerList = [
			...(await (await existingRef.get()).getSet('code')),
		];

		const globalRef =
			FilteredDatabase.ref('globalCustomers').customers(customerList);

		const globalCards: CustomerSupplierDbCard[] = await (
			await globalRef.get()
		).getCards();

		const customerAreaList = {};

		for (const global of globalCards) {
			if (!customerAreaList[global.areaDesc]) {
				customerAreaList[global.areaDesc] = [];
			}
			customerAreaList[global.areaDesc].push(global.code);
		}

		tradeIvdnRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.selectedDateRange)
			.agents(agentList)
			.customers(customerList)
			.includes('docType', ['IV', 'DN']);

		const tradeCNRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.selectedDateRange)
			.agents(agentList)
			.customers(customerList)
			.includes('docType', ['CN']);

		const newTradeIvdnRef = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.customers(newCustomerList)
			.includes('docType', ['IV', 'DN']);

		const newTradeCNRef = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.customers(newCustomerList)
			.includes('docType', ['CN']);

		const existingTradeIvdnRef = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.customers(existingCustomerList)
			.includes('docType', ['IV', 'DN']);

		const existingTradeCNRef = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.customers(existingCustomerList)
			.includes('docType', ['CN']);

		let tradeDD: DimensionData[] = [];
		let tradeCNDD: DimensionData[] = [];

		let pa = 0.16;
		let pb = 0;

		const newCustomerTradeIvdnDD = await this._loadDimensionByFilters(
			newTradeIvdnRef,
			'customers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.16;

		const newCustomerTradeCNDD = await this._loadDimensionByFilters(
			newTradeCNRef,
			'customers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.16;

		const existingCustomerTradeIvdnDD = await this._loadDimensionByFilters(
			existingTradeIvdnRef,
			'customers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.16;

		const existingCustomerTradeCNDD = await this._loadDimensionByFilters(
			existingTradeCNRef,
			'customers',
			pa,
			pb,
			'amount',
		);

		switch (this.selectedGrouping) {
			case 'stockGroup':
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					salesRef,
					'stockGroup',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(
							reff,
							'stockItems',
							paa,
							pbb,
							'amount',
						),
				);
				tradeCNDD = await this._loadDimensionByFilters(
					cnRef,
					'stockGroup',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(
							reff,
							'stockItems',
							paa,
							pbb,
							'amount',
						),
				);
				break;
			case 'stockItems':
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					salesRef,
					'stockItems',
					pa,
					pb,
					'amount',
				);
				tradeCNDD = await this._loadDimensionByFilters(
					cnRef,
					'stockItems',
					pa,
					pb,
					'amount',
				);
				break;
			case 'customerCategory':
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					tradeIvdnRef,
					'customerCategory',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(reff, 'customers', paa, pbb, 'amount'),
				);

				pb += pa;
				pa = 0.16;

				tradeCNDD = await this._loadDimensionByFilters(
					tradeCNRef,
					'customerCategory',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(reff, 'customers', paa, pbb, 'amount'),
				);
				break;
			case 'salesAgent':
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					tradeIvdnRef,
					'agents',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(reff, 'customers', paa, pbb, 'amount'),
				);

				pb += pa;
				pa = 0.16;

				tradeCNDD = await this._loadDimensionByFilters(
					tradeCNRef,
					'agents',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(reff, 'customers', paa, pbb, 'amount'),
				);
				break;
			case 'newCustomer':
				pb += pa;
				pa = 0.16;

				tradeDD = newCustomerTradeIvdnDD;

				pb += pa;
				pa = 0.16;

				tradeCNDD = newCustomerTradeCNDD;
				break;
			case 'currentCustomer':
				pb += pa;
				pa = 0.16;

				tradeDD = existingCustomerTradeIvdnDD;

				pb += pa;
				pa = 0.16;

				tradeCNDD = existingCustomerTradeCNDD;
				break;
			case 'customerSize':
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					tradeIvdnRef,
					'customers',
					pa,
					pb,
					'amount',
				);

				pb += pa;
				pa = 0.16;

				tradeCNDD = await this._loadDimensionByFilters(
					tradeCNRef,
					'customers',
					pa,
					pb,
					'amount',
				);
				break;
			default:
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					tradeIvdnRef,
					'customers',
					pa,
					pb,
					'amount',
				);
				pb += pa;
				pa = 0.16;

				tradeCNDD = await this._loadDimensionByFilters(
					tradeCNRef,
					'customers',
					pa,
					pb,
					'amount',
				);
		}
		this.generateLoadingText(1);

		this.result = [];

		if (this.selectedGrouping === 'currentNew') {
			this.result.push({
				name: 'New Customer',
				value:
					newCustomerTradeIvdnDD
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0) -
					newCustomerTradeCNDD
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0),
				color: [
					'#8975ea',
					'#acbeff',
					'#accdff',
					'#acdaff',
					'#cdc8f9',
					'#c7b5e2',
				],
				colorSaturation: [0.9, 0.4],
				children: newCustomerTradeIvdnDD.map((customer, index) => ({
					name: customer.text,
					value: customer.sum - newCustomerTradeCNDD[index].sum,
					color: [
						'#8975ea',
						'#acbeff',
						'#accdff',
						'#acdaff',
						'#cdc8f9',
						'#c7b5e2',
					],
					colorSaturation: [0.9, 0.4],
				})),
			});
			this.result.push({
				name: 'Existing Customer',
				value:
					existingCustomerTradeIvdnDD
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0) -
					existingCustomerTradeCNDD
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0),
				color: [
					'#8975ea',
					'#acbeff',
					'#accdff',
					'#acdaff',
					'#cdc8f9',
					'#c7b5e2',
				],
				colorSaturation: [0.9, 0.4],
				children: existingCustomerTradeIvdnDD.map((customer, index) => ({
					name: customer.text,
					value: customer.sum - existingCustomerTradeCNDD[index].sum,
					color: [
						'#8975ea',
						'#acbeff',
						'#accdff',
						'#acdaff',
						'#cdc8f9',
						'#c7b5e2',
					],
					colorSaturation: [0.9, 0.4],
				})),
			});
		} else if (this.selectedGrouping === 'customerSize') {
			this.result.push({
				name: 'Above ' + String(this.bigCustomerInput),
				value:
					tradeDD
						.filter((dd) => dd.sum > this.bigCustomerInput)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0) -
					tradeCNDD
						.filter((dd) => dd.sum > this.bigCustomerInput)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0),
				color: [
					'#8975ea',
					'#acbeff',
					'#accdff',
					'#acdaff',
					'#cdc8f9',
					'#c7b5e2',
				],
				colorSaturation: [0.9, 0.4],
				children: tradeDD
					.filter((dd) => dd.sum > this.bigCustomerInput)
					.map((customer) => {
						const object = {
							name: customer.text,
							value: 0,
							color: [
								'#8975ea',
								'#acbeff',
								'#accdff',
								'#acdaff',
								'#cdc8f9',
								'#c7b5e2',
							],
							colorSaturation: [0.9, 0.4],
						};
						const cnIndex = tradeCNDD.findIndex(
							(cn) => cn.value[0] === customer.value[0],
						);

						object.value = customer.sum;

						return object;
					}),
			});
			this.result.push({
				name:
					'Between ' +
					String(this.smallCustomerInput) +
					' and ' +
					String(this.bigCustomerInput),
				value:
					tradeDD
						.filter(
							(dd) =>
								dd.sum >= this.smallCustomerInput &&
								dd.sum <= this.bigCustomerInput,
						)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0) -
					tradeCNDD
						.filter(
							(dd) =>
								dd.sum >= this.smallCustomerInput &&
								dd.sum <= this.bigCustomerInput,
						)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0),
				color: [
					'#8975ea',
					'#acbeff',
					'#accdff',
					'#acdaff',
					'#cdc8f9',
					'#c7b5e2',
				],
				colorSaturation: [0.9, 0.4],
				children: tradeDD
					.filter(
						(dd) =>
							dd.sum >= this.smallCustomerInput &&
							dd.sum <= this.bigCustomerInput,
					)
					.map((customer) => {
						const object = {
							name: customer.text,
							value: 0,
							color: [
								'#8975ea',
								'#acbeff',
								'#accdff',
								'#acdaff',
								'#cdc8f9',
								'#c7b5e2',
							],
							colorSaturation: [0.9, 0.4],
						};

						const cnIndex = tradeCNDD.findIndex(
							(cn) => cn.value[0] === customer.value[0],
						);

						object.value = customer.sum;

						return object;
					}),
			});
			this.result.push({
				name: 'Below ' + String(this.smallCustomerInput),
				value:
					tradeDD
						.filter((dd) => dd.sum < this.smallCustomerInput)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0) -
					tradeCNDD
						.filter((dd) => dd.sum < this.smallCustomerInput)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0),
				color: [
					'#8975ea',
					'#acbeff',
					'#accdff',
					'#acdaff',
					'#cdc8f9',
					'#c7b5e2',
				],
				colorSaturation: [0.9, 0.4],
				children: tradeDD
					.filter((dd) => dd.sum < this.smallCustomerInput)
					.map((customer) => {
						const object = {
							name: customer.text,
							value: 0,
						};

						const cnIndex = tradeCNDD.findIndex(
							(cn) => cn.value[0] === customer.value[0],
						);

						object.value = customer.sum;

						return object;
					}),
			});
		} else if (this.selectedGrouping === 'area') {
			const areaList = Object.keys(customerAreaList);
			const codeList: string[][] = Object.values(customerAreaList);

			areaList.forEach((area, index) => {
				const codes: string[] = codeList[index];
				const object = {
					name: area,
					value: 0,
					color: [
						'#8975ea',
						'#acbeff',
						'#accdff',
						'#acdaff',
						'#cdc8f9',
						'#c7b5e2',
					],
					colorSaturation: [0.9, 0.4],
					children: [] as Array<{
						name: string;
						value: number;
						color: string[];
					}>,
				};

				codes.forEach((code) => {
					const ivdn = tradeDD.find((dd) => dd.value[0] === code);
					const cn = tradeCNDD.find((dd) => dd.value[0] === code);
					if (ivdn && cn) {
						object.children.push({
							name: ivdn.text,
							value: ivdn.sum - cn.sum,
							color: [
								'#8975ea',
								'#acbeff',
								'#accdff',
								'#acdaff',
								'#cdc8f9',
								'#c7b5e2',
							],
						});
					}
					if (ivdn) {
						object.children.push({
							name: ivdn.text,
							value: ivdn.sum,
							color: [
								'#8975ea',
								'#acbeff',
								'#accdff',
								'#acdaff',
								'#cdc8f9',
								'#c7b5e2',
							],
						});
					}
				});
				object.value = object.children
					.map((item) => item.value)
					.reduce((a, b) => a + b, 0);
				this.result.push(object);
			});
		} else {
			tradeDD.forEach((trade, index) => {
				const subDD = trade.subDimension;
				const cnSubDD = tradeCNDD[index].subDimension;
				this.result.push({
					name: trade.text,
					value: trade.sum,
					color: [
						'#8975ea',
						'#acbeff',
						'#accdff',
						'#acdaff',
						'#cdc8f9',
						'#c7b5e2',
					],
					colorSaturation: [0.9, 0.4],
					children:
						subDD && cnSubDD
							? subDD.map((subdd, subIndex) => ({
									name: subdd.text,
									value: subdd.sum - cnSubDD[subIndex].sum,
									color: [
										'#8975ea',
										'#acbeff',
										'#accdff',
										'#acdaff',
										'#cdc8f9',
										'#c7b5e2',
									],
									colorSaturation: [0.9, 0.4],
							  }))
							: subDD
							? subDD.map((subdd) => ({
									name: subdd.text,
									value: subdd.sum,
									color: [
										'#8975ea',
										'#acbeff',
										'#accdff',
										'#acdaff',
										'#cdc8f9',
										'#c7b5e2',
									],
									colorSaturation: [0.9, 0.4],
							  }))
							: [],
				});
			});
		}

		this.result = this.result.filter((item) => item.value > 0);

		this.series = [];

		const stock = {
			name: 'Customer Analysis',
			type: 'treemap',
			roam: 'move',
			width: '100%',
			// drillDownIcon: '👆🏻',
			drillDownIcon: '',
			// leafDepth: 1,
			label: {
				color: '#000',
			},
			itemStyle: {
				gapWidth: 1,
				borderWidth: 1,
			},
			levels: [
				{
					itemStyle: {
						// borderColor: '#555',
						borderWidth: 2,
						gapWidth: 2,
					},
					upperLabel: {
						show: false,
						fontWeight: 'bold',
						color: '#000',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 2,
						},
						upperLabel: {
							show: true,
							fontWeight: 'bold',
							color: '#000',
						},
					},
				},
				{
					colorSaturation: [0.25, 0.6],
					itemStyle: {
						borderColorSaturation: 0.7,
						gapWidth: 2,
						borderWidth: 2,
					},
					upperLabel: {
						show: true,
						color: '#000',
						fontWeight: 'bold',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 1,
						},
						upperLabel: {
							show: true,
							color: '#000',
							fontWeight: 'bold',
						},
					},
				},
				{
					itemStyle: {
						// borderColor: '#555',
						gapWidth: 1,
						borderWidth: 1,
					},
					upperLabel: {
						show: true,
						color: '#000',
						fontWeight: 'bold',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 1,
						},
						upperLabel: {
							show: true,
							color: '#000',
							fontWeight: 'bold',
						},
					},
				},
			],
			data: this.result,
		};

		this.series = [stock];

		this.saveHistory(
			'result',
			'series',
			'bigCustomerInput',
			'smallCustomerInput',
		);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
