
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	DimensionData,
	DimensionMap,
	PermissionsGroup,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import * as echarts from 'echarts';
import moment from 'moment';
import { GobiColor } from '@/helpers/color';

@Component({
	components: {
		StackedBarChartFilterable,
		DateFilter,
	},
})
export default class CustomerPaymentAnalysisBarChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'paymentAnalysisYear'
	> = ['customers', 'agents', 'paymentAnalysisYear'];

	public xAxisData: string[] = [];
	public series: echarts.ECharts[] = [];

	public selectedStartDateOfYear: number = moment().startOf('year').valueOf();

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get expensiveHook() {
		const { selectedStartDateOfYear, selectedAgents, selectedCustomers } = this;
		return JSON.stringify([
			selectedStartDateOfYear,
			selectedAgents,
			selectedCustomers,
		]);
	}

	public async expensiveCalc() {
		let pa = 0.6;
		let pb = 0;

		const msInDay = 24 * 60 * 60 * 1000;

		const filterType = 'number';
		const filterKey = 'dueLen';

		const ref = FilteredDatabase.ref('customerKos')
			.agents(this.selectedAgents)
			.year(moment(this.selectedStartDateOfYear).year(), 'dueDate')
			.customers(this.selectedCustomers);

		const dueLenMap: DimensionMap[] = [
			{
				text: 'On Time',
				value: [
					['>', Number.NEGATIVE_INFINITY],
					['<=', 0],
				],
				filterKey,
				filterType,
			},
			{
				text: '30 Days',
				value: [
					['>', 0],
					['<=', 30 * msInDay],
				],
				filterKey,
				filterType,
			},
			{
				text: 'Over 30 Days',
				value: [
					['>', 30 * msInDay],
					['<', Number.POSITIVE_INFINITY],
				],
				filterKey,
				filterType,
			},
		];

		const amountResults: DimensionData[] = await this._loadDimension(
			ref,
			dueLenMap,
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimensionByPeriod(
					reff,
					'month',
					paa,
					pbb,
					'amountGainLoss',
					'dueDate',
				),
		);

		const series: echarts.ECharts[] | any = amountResults.map((dd, i) => ({
			name: dd.text,
			type: 'bar',
			stack: 'a',
			barWidth: '40%',
			color:
				dd.text === 'On Time'
					? GobiColor.COLOR1
					: dd.text === '30 Days'
					? GobiColor.COLOR2
					: GobiColor.COLOR3,
			data: dd.subDimension!.map((sd, j) => sd.sum),
		}));

		const collected: number[] = [];
		const periodLen = series[0].data!.length;
		const columnLen = series.length;
		for (let i = 0; i < periodLen; i++) {
			collected[i] = 0;
			for (let j = 0; j < columnLen; j++) {
				collected[i] += series[j].data![i] as number;
			}
		}

		pb += pa;
		pa = 0.4;

		const ref2 = FilteredDatabase.ref('customerIvDns')
			.agents(this.selectedAgents)
			.year(moment(this.selectedStartDateOfYear).year(), 'dueDate')
			.customers(this.selectedCustomers);
		const monthlyDueResults = await this._loadDimensionByPeriod(
			ref2,
			'month',
			pa,
			pb,
			'amount',
			'dueDate',
		);

  const test = await (await ref2.get()).getCards();

		this.xAxisData = monthlyDueResults.map((dd) => dd.text);

		this.series = series;
		this.saveHistory('series', 'xAxisData');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
