
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Page from './Page.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import CustomerAnalysisTreeMap from '../../components/codedWidgets/customer/CustomerAnalysisTreeMap.vue';
// tslint:disable-next-line:max-line-length
import CustomerPaymentAnalysisBarChart from '../../components/codedWidgets/customer/CustomerPaymentAnalysisBarChart.vue';
import CustomerAnalysisPieChart from '../../components/codedWidgets/customer/CustomerAnalysisPieChart.vue';
import DocumentAnalysisBarChart from '../../components/codedWidgets/customer/DocumentAnalysisBarChart.vue';
import echarts from 'echarts';
import {TooltipFormatter} from '@/components/reports-v2/components/elements/charts/helpers/tooltipFormatter';
import {DataViewFormatter} from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';

@Component({
	components: {
		CustomerAnalysisTreeMap,
		CustomerPaymentAnalysisBarChart,
		DocumentAnalysisBarChart,
    CustomerAnalysisPieChart,
	},
})
export default class Page2 extends Page {

  public seriesYear1Amount: echarts.ECharts[] | any = [];

  public get tooltipFormatter() {
    return TooltipFormatter.pieChart;
  }

  public get dataViewFormatter() {
    return DataViewFormatter.pieChart;
  }

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}
}
