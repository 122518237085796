
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { DimensionMap, PermissionsGroup } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import { DataViewFormatter } from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';

@Component({
	components: {
		StackedBarChartFilterable,
	},
})
export default class DocumentAnalysisBarChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['customers', 'agents', 'dateRange'];

	public amount: number[] = [0, 0, 0, 0, 0];
	public result: any = [];
	public xAxisData: string[] = [];
	public series: echarts.ECharts[] | any = [];

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get dataViewFormatter() {
		return DataViewFormatter.barChart;
	}

	public get expensiveHook() {
		const { selectedDateRange, selectedAgents, selectedCustomers } = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAgents,
			selectedCustomers,
		]);
	}

	public async expensiveCalc() {
		const codeList: string[] = ['IV', 'DN', 'PM', 'CN', 'CT'].reverse();

		const nameList: string[] = [
			'Invoice',
			'Debit Note',
			'Payment',
			'Credit Note',
			'Contra',
		].reverse();

		const map: DimensionMap[] = codeList.map((code, index) => ({
			filterType: 'string',
			filterKey: 'docType',
			value: [code],
			text: nameList[index],
		}));

		let pa = 0.5;
		let pb = 0;

		const tradeRef = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.customers(this.selectedCustomers);

		const customerList = [...(await (await tradeRef.get()).getSet('code'))];

		const tradeRef2 = FilteredDatabase.ref('customerTrades').customers(
			customerList,
		);

		pb += pa;
		pa = 0.5;

		const amountDD = await this._loadDimension(tradeRef, map, pa, pb, 'amount');

		this.xAxisData = amountDD.map((dd) => dd.text);

		amountDD.forEach((dd, ddIndex) => {
			const i = nameList.indexOf(dd.text);
			if (i === -1) {
				return;
			}
			this.amount[i] = dd.sum;
		});

		this.result = [];

		this.amount.forEach((item, index) => {
			const template = { value: 0, itemStyle: { color: '' } };

			template.value = this.amount[index];
			template.itemStyle.color =
				index > 2 ? GobiColor.COLOR1 : GobiColor.COLOR3;
			this.result.push(template);
		});

		this.series = [
			{
				name: 'Amount',
				data: this.result,
				type: 'bar',
				barWidth: '40%',
			},
		];

		this.saveHistory('series', 'xAxisData', 'result', 'amount');
	}
}
